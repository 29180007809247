<template>
  <div class="py-7">
    <!-- search input -->
    <v-row
      justify="space-between"
      align="center"
      class="col-12 px-7"
      no-gutters>
      <v-col>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          label="Search for an exercise"
          dense
          class="mr-5"
          @focus="$emit('initSearch')">
        </v-text-field>
      </v-col>
      <v-btn
        icon
        @click="$emit('initSearch', true)">
        <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
      </v-btn>
    </v-row>
    <div
      v-if="isLoading"
      class="mt-12 px-7">
      <v-progress-linear
        indeterminate
        color="primaryColor"></v-progress-linear>
    </div>
    <!-- popular Categories -->
    <div
      v-if="popularCategories && popularCategories.length && !isLoading"
      class="px-4 mb-7">
      <div class="section-title text-center text-uppercase mt-14 mb-1">Most Popular Categories</div>
      <div>
        <v-row
          class="col-12 px-0 ma-0"
          justify="space-between"
          align="start"
          no-gutters>
          <v-col
            @click="goToCategoryCourses(category.id)"
            class="px-4 category-container"
            cols="4"
            v-for="category in popularCategories"
            :key="category.id">
            <CategoryItem
              class="category-item"
              :name="category.name"
              :cover="category.coverUrl" />
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      v-if="unpopularCategories && unpopularCategories.length && !isLoading"
      class="offWhite px-4">
      <div class="section-title text-center text-uppercase pt-6 mb-1">More Categories</div>
      <div>
        <v-row
          class="col-12 px-0 ma-0"
          justify="space-between"
          align="start"
          no-gutters>
          <v-col
            @click="goToCategoryCourses(category.id)"
            class="px-4 py-2 category-container"
            cols="4"
            v-for="category in unpopularCategories"
            :key="category.id">
            <CategoryItem
              class="category-item"
              :name="category.name"
              :cover="category.coverUrl" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import ExplorerScreenMixin from '@/components/courses/explorer-screen/ExplorerScreen.mixin.vue';
  export default {
    mixins: [ExplorerScreenMixin],
  };
</script>

<style lang="scss" scoped></style>
