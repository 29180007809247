<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import CategoryItem from '@/components/categories/category-item/CategoryItem.vue';

  export default {
    name: 'explorer-screen',
    data() {
      return {
        isLoading: false,
      };
    },
    components: {
      CategoryItem,
    },
    computed: {
      ...mapGetters('CategoriesModule', ['categories', 'unpopularCategories', 'popularCategories']),
    },
    created() {
      this.fetchCategories();
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CategoriesModule', ['fetchAll']),
      async fetchCategories() {
        this.isLoading = true;
        try {
          await this.fetchAll();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoading = false;
        }
      },
      goToCategoryCourses(categoryId) {
        this.$router.push({
          name: 'CoursesByCategory',
          params: {
            categoryId,
          },
        });
      },
    },
  };
</script>
